import { GetServerSidePropsContext } from 'next'

import { setCachingFor } from '../support/helpers';
import { taglineFor } from '../support/taglines';
import LandingPage from '../pcomponents/LandingPage';

interface Props {
  apiBaseUrl: string,
}

function Home(props: Props) {

  let taglineIndex = 1;
  const taglines = [
    // taglineFor(<div className="primary">
    //   <span className="nbsp">Design your own</span>< br />
    //   <span className="nbsp">real live 3D objects.</span><br />
    //   <span className="nbsp">It&apos;s <b>super</b> em<b>power</b>ing.</span>
    //   {/* <span className="nbsp"><b>is a superpower.</b></span> */}
    // </div>, taglineIndex++),
    taglineFor(<div className="primary">
      <div className="sub">Now kids can be toy creators, not just consumers.</div>
      <div>So easy, grown-ups can do it too.</div>
    </div>, taglineIndex++),
    taglineFor(<div>If you can sketch, edit, trace, or even find simple 2D pictures, you can design your own toys, art, jewelry, or any other 3D objects, for free.</div>, taglineIndex++),
    taglineFor(<div>Order your favorites. We&apos;ll 3D print and send them to you.</div>, taglineIndex++),
  ];

  return (
    <LandingPage
      taglines={taglines}
      apiBaseUrl={props.apiBaseUrl}
    />
  );

}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  setCachingFor(ctx);
  return Promise.resolve({
    props: {
      apiBaseUrl: process.env.API_BASE_URL,
      isSizeConstrained: true,
    }
  });
}

export default Home;